<template>
	<div style="display:block" class="layer">
		<div class="layer__dim"></div>
		<div class="layer__full">
			<div class="layer__fullbox">
				<div class="layer__fullheader">
					<nav class="nav">
						<div class="nav__wrap">
							<a @click="goBack" class="nav__btn nav__btn--prev">
								<i class="icon icon-close">닫기</i><!-- 재번역체크 -->
							</a>
							<p class="nav__item">
								<span class="nav__text">
									{{t('10736')}}
								</span>
							</p>
							<a class="nav__btn nav__btn--map">
							</a>
						</div>
					</nav>
				</div>
				<div class="layer__fullbody">
					<div class="row">
						<div class="terms__wrap">
							<div class="terms__box" v-if="data !== undefined">
								<h3 class="terms__title">{{t('10736')}}</h3>
								<div class="terms__desc" v-for="(text, index) in data[0].CANCEL_POLICY" :key="index" >
									{{text}}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { computed, onMounted } from '@vue/runtime-core'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n' 
import i18n from "@/i18n"; 
export default {
	setup() {
		const store = useStore();
        const router = useRouter();
		const { t }= useI18n() 
		onMounted(() => {
			store.dispatch("mcs01/fetchMcs01", {proc_cd: "09"});
		})
		return {
			data: computed(() => store.state.mcs01.mcs01["09"]),
			t,  //번역필수 모듈
		  i18n, 
            goBack(){
                router.back();
            }
		}
	},
}
</script>